import PropTypes from "prop-types"
import React from "react"

import slider_1 from '../../static/img/irstrat/index_1.jpg'

const RevSliderEn = ({siteTitle}) => (

    <section className="main_slider_area">
        <div id="main_slider" className="rev_slider" data-version="5.3.1.6">
            <ul>
                <li
                    data-index="rs-1587"
                    data-transition="fade"
                    data-slotamount="default"
                    data-hideafterloop={0}
                    data-hideslideonmobile="off"
                    data-easein="default"
                    data-easeout="default"
                    data-masterspeed={300}
                    data-thumb={slider_1}
                    data-rotate={0}
                    data-saveperformance="off"
                    data-title="Creative"
                    data-description
                >
                    {/* MAIN IMAGE */}
                    <img
                        src={slider_1}
                        data-bgposition="center center"
                        data-bgfit="cover"
                        data-bgrepeat="no-repeat"
                        data-bgparallax={5}
                        className="rev-slidebg"
                        data-no-retina
                    />
                    {/* LAYER NR. 1 */}
                    <div className="slider_text_box">

                        <div
                            className="tp-caption tp-resizeme text-uppercase"
                            id="slide-1586-layer-1"
                            data-x="['left','left','center','center','center']"
                            data-hoffset="['100','100','0','0']"
                            data-y="['top','top','top','top']"
                            data-voffset="['240','240','240','240','190']"
                            data-fontsize="['55','55','55','55','26']"
                            data-lineheight="['70','68','68','68','40']"
                            data-width="['580','580','580','580','290']"
                            data-height="none"
                            data-whitespace="normal"
                            data-type="text"
                            data-responsive_offset="on"
                            data-frames='[{"delay":10,"speed":1500,"frame":"0","from":"y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","to":"o:1;","ease":"Power2.easeInOut"},{"delay":"wait","speed":1500,"frame":"999","to":"y:[175%];","mask":"x:inherit;y:inherit;s:inherit;e:inherit;","ease":"Power2.easeInOut"}]'
                            data-textalign="['left','left','left','center']"
                            style={{
                                zIndex: 6,
                                fontFamily: '"Poppins", sans-serif',
                                fontWeight: 600,
                                color: "#fff"
                            }}
                        >
                            STRATEGIC CONSULTANCY
                        </div>
                        <div
                            className="tp-caption tp-resizeme p_font"
                            id="slide-1587-layer-2"
                            data-x="['left','left','left','center','0']"
                            data-hoffset="['100','100', '90', '0']"
                            data-y="['top','top','top','top']"
                            data-voffset="['380','380','380']"
                            data-fontsize="['18','18','18']"
                            data-lineheight="['26','26','26']"
                            data-width="['580','580','550','550','300']"
                            data-height="none"
                            data-whitespace="normal"
                            data-type="text"
                            data-responsive_offset="on"
                            data-transform_idle="o:1;"
                            data-frames='[{"delay":10,"speed":1500,"frame":"0","from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","mask":"x:0px;y:[100%];s:inherit;e:inherit;","to":"o:1;","ease":"Power2.easeInOut"},{"delay":"wait","speed":1500,"frame":"999","to":"y:[175%];","mask":"x:inherit;y:inherit;s:inherit;e:inherit;","ease":"Power2.easeInOut"}]'
                            data-textalign="['left','left','left','center']"
                            style={{
                                zIndex: 6,
                                fontFamily: '"Poppins", sans-serif',
                                fontWeight: 400,
                                color: "#fff"
                            }}
                        >
                            Specialized in investor relations, corporate finance <br/>and financial PR
                        </div>
                        {/*<div*/}
                        {/*className="tp-caption tp-resizeme"*/}
                        {/*id="slide-1588-layer-3"*/}
                        {/*data-x="['left','left','left','center','center']"*/}
                        {/*data-hoffset="['0','0','0','10']"*/}
                        {/*data-y="['top','top','top','top']"*/}
                        {/*data-voffset="['650','650','538','538','300']"*/}
                        {/*data-fontsize="['16','16','16','14']"*/}
                        {/*data-lineheight="['46','46','46','46']"*/}
                        {/*data-width="none"*/}
                        {/*data-height="none"*/}
                        {/*data-whitespace="nowrap"*/}
                        {/*data-type="text"*/}
                        {/*data-responsive_offset="on"*/}
                        {/*data-frames='[{"delay":10,"speed":1500,"frame":"0","from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","mask":"x:0px;y:[100%];s:inherit;e:inherit;","to":"o:1;","ease":"Power2.easeInOut"},{"delay":"wait","speed":1500,"frame":"999","to":"y:[175%];","mask":"x:inherit;y:inherit;s:inherit;e:inherit;","ease":"Power2.easeInOut"}]'*/}
                        {/*>*/}
                        {/*<a className="slider_btn btn_hover hover_color" href="#">*/}
                        {/*View Our Services*/}
                        {/*</a>*/}
                        {/*</div>*/}
                    </div>
                </li>
                <li
                    data-index="rs-1588"
                    data-transition="fade"
                    data-slotamount="default"
                    data-hideafterloop={0}
                    data-hideslideonmobile="off"
                    data-easein="default"
                    data-easeout="default"
                    data-masterspeed={300}
                    data-thumb={slider_1}
                    data-rotate={0}
                    data-saveperformance="off"
                    data-title="Creative"
                    data-description
                >
                    {/*  MAIN IMAGE */}
                    <img
                        src={slider_1}
                        data-bgposition="center center"
                        data-bgfit="cover"
                        data-bgrepeat="no-repeat"
                        data-bgparallax={5}
                        className="rev-slidebg"
                        data-no-retina
                    />
                    <div className="slider_text_box">
                        {/*<div*/}
                        {/*className="tp-caption tp-resizeme"*/}
                        {/*id="slide-1589-layer-1"*/}
                        {/*data-x="['left','left','left','left','left']"*/}
                        {/*data-hoffset="['0','0','0','0','0']"*/}
                        {/*data-y="['top','top','top','top']"*/}
                        {/*data-voffset="['340','340','220','220','145']"*/}
                        {/*data-fontsize="['22','22','22','22']"*/}
                        {/*data-lineheight="['30','30','30']"*/}
                        {/*data-width="['550','550','550','550','290']"*/}
                        {/*data-height="none"*/}
                        {/*data-whitespace="normal"*/}
                        {/*data-type="text"*/}
                        {/*data-responsive_offset="on"*/}
                        {/*data-frames='[{"delay":10,"speed":1500,"frame":"0","from":"y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","to":"o:1;","ease":"Power2.easeInOut"},{"delay":"wait","speed":1500,"frame":"999","to":"y:[175%];","mask":"x:inherit;y:inherit;s:inherit;e:inherit;","ease":"Power2.easeInOut"}]'*/}
                        {/*data-textalign="['left','left','left','center','left']"*/}
                        {/*style={{*/}
                        {/*zIndex: 6,*/}
                        {/*fontFamily: '"Lato", sans-serif',*/}
                        {/*fontStyle: "italic",*/}
                        {/*color: "#fff"*/}
                        {/*}}*/}
                        {/*>*/}
                        {/*Welcome To Unilevor*/}
                        {/*</div>*/}
                        {/*<div*/}
                        {/*className="tp-caption tp-shape tp-shapewrapper rs-parallaxlevel-3"*/}
                        {/*data-x="['left','left','left','center']"*/}
                        {/*data-hoffset="['220','220','210','90']"*/}
                        {/*data-y="['middle','middle','middle','middle']"*/}
                        {/*data-voffset="['-88','-88','-140','-144','-84']"*/}
                        {/*data-width={80}*/}
                        {/*data-height={1}*/}
                        {/*data-whitespace="nowrap"*/}
                        {/*data-type="shape"*/}
                        {/*data-responsive_offset="on"*/}
                        {/*data-responsive="off"*/}
                        {/*data-frames='[{"from":"x:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","speed":1000,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},{"delay":"wait","speed":500,"to":"y:[100%];","mask":"x:inherit;y:inherit;s:inherit;e:inherit;","ease":"Power1.easeIn"}]'*/}
                        {/*data-textalign="['left','left','left','left']"*/}
                        {/*data-paddingtop="[0,0,0,0]"*/}
                        {/*data-paddingright="[0,0,0,0]"*/}
                        {/*data-paddingbottom="[0,0,0,0]"*/}
                        {/*data-paddingleft="[0,0,0,0]"*/}
                        {/*style={{*/}
                        {/*zIndex: 6,*/}
                        {/*backgroundColor: "rgba(255, 255, 255, 1.00)",*/}
                        {/*borderColor: "rgba(0, 0, 0, 0)",*/}
                        {/*borderWidth: 0*/}
                        {/*}}*/}
                        {/*></div>*/}
                        <div
                            className="tp-caption tp-resizeme text-uppercase"
                            data-x="['left','left','left','center','center']"
                            data-hoffset="['100','100','100','0']"
                            data-y="['top','top','top','top']"
                            data-voffset="['200','200','200','200','200']"
                            data-fontsize="['55','55','47','45','34']"
                            data-lineheight="['70','68','68','68','40']"
                            data-width="['800','800','700','600','290']"
                            data-height="none"
                            data-whitespace="normal"
                            data-type="text"
                            data-responsive_offset="on"
                            data-frames='[{"delay":10,"speed":1500,"frame":"0","from":"y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","to":"o:1;","ease":"Power2.easeInOut"},{"delay":"wait","speed":1500,"frame":"999","to":"y:[175%];","mask":"x:inherit;y:inherit;s:inherit;e:inherit;","ease":"Power2.easeInOut"}]'
                            data-textalign="['left','left','left','center']"
                            style={{
                                zIndex: 6,
                                fontFamily: '"Poppins", sans-serif',
                                fontWeight: 600,
                                color: "#fff"
                            }}
                        >
                            We speak the market’s language
                        </div>
                        <div
                            className="tp-caption tp-resizeme p_font"
                            id="slide-1590-layer-2"
                            data-x="['left','left','left','center','0']"
                            data-hoffset="['100','100', '100', '0']"
                            data-y="['top','top','top','top']"
                            data-voffset="['350','350','370', '370']"
                            data-fontsize="['18','18','18']"
                            data-lineheight="['26','26','26']"
                            data-width="['580','580','550','550','300']"
                            data-height="none"
                            data-whitespace="normal"
                            data-type="text"
                            data-responsive_offset="on"
                            data-transform_idle="o:1;"
                            data-frames='[{"delay":10,"speed":1500,"frame":"0","from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","mask":"x:0px;y:[100%];s:inherit;e:inherit;","to":"o:1;","ease":"Power2.easeInOut"},{"delay":"wait","speed":1500,"frame":"999","to":"y:[175%];","mask":"x:inherit;y:inherit;s:inherit;e:inherit;","ease":"Power2.easeInOut"}]'
                            data-textalign="['left','left','left','center']"
                            style={{
                                zIndex: 6,
                                fontFamily: '"Poppins", sans-serif',
                                fontWeight: 400,
                                color: "#fff"
                            }}
                        >
                            Revolutionizing communications and financial PR in Mexico with strategy and
                            execution
                        </div>
                        <div
                            className="tp-caption tp-resizeme"
                            id="slide-1591-layer-3"
                            data-x="['left','left','left','center','center']"
                            data-hoffset="['100','100','100','0']"
                            data-y="['top','top','top','top']"
                            data-voffset="['450','450','470','470','390']"
                            data-fontsize="['16','16','16','14']"
                            data-lineheight="['46','46','46','46']"
                            data-width="none"
                            data-height="none"
                            data-whitespace="nowrap"
                            data-type="text"
                            data-responsive_offset="on"
                            data-frames='[{"delay":10,"speed":1500,"frame":"0","from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","mask":"x:0px;y:[100%];s:inherit;e:inherit;","to":"o:1;","ease":"Power2.easeInOut"},{"delay":"wait","speed":1500,"frame":"999","to":"y:[175%];","mask":"x:inherit;y:inherit;s:inherit;e:inherit;","ease":"Power2.easeInOut"}]'
                        >
                            <a className="slider_btn btn_hover hover_color" href="es/servicios">
                                Learn about our services
                            </a>
                        </div>
                    </div>
                </li>

            </ul>
        </div>

    </section>


)

RevSliderEn.propTypes = {
    siteTitle: PropTypes.string
}

RevSliderEn.defaultProps = {
    siteTitle: ``
}

export default RevSliderEn
