import React, {useEffect, useRef} from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import RevSliderEn from "../components/rev-slider-en"
import CustomLink from "../components/link"
import popup from "../../static/img/popup_mps_en.jpg";
import img2 from "../../static/img/img2.jpg";
import solucion1 from "../../static/img/irstrat/solucion1.jpg";
import solucion2 from "../../static/img/irstrat/solucion2.jpg";
import solucion3 from "../../static/img/irstrat/solucion3.jpg";
import iredge from "../../static/img/irstrat/iredge.svg";
import sustentabilidad from "../../static/img/irstrat/sustentabilidad.svg";
import interactivo from "../../static/img/irstrat/interactivo.svg";
import financieros from "../../static/img/irstrat/financieros.svg";
import noticias_20 from "../../static/img/blog/noticias-20.jpg";
import noticias_21 from "../../static/img/blog/noticias-21.jpg";
import noticias_19 from "../../static/img/blog/noticias-19.jpg";
import noticias_18 from "../../static/img/blog/noticias-18.jpg";

const IndexPage = (props) => {
    const isMountedComponent = useRef(true)
    useEffect(() => {
        if (isMountedComponent.current) {
            window.jquery_scripts()
        }
        return () => {
            isMountedComponent.current = false
        }
    })

    return (
        <Layout location={props.location}>
            <SEO title="Home"/>
            <RevSliderEn/>
            <section className="pt-5 mt-5">
                <div className="container">
                    <div className="row d-flex align-items-center flex-wrap">
                        {/* Heading Area*/}
                        <div className="col-12 col-lg-6 text-center text-lg-left mb-5 mb-md-0 wow fadeInLeft"
                             data-wow-delay="0.1s"
                             data-wow-duration="1.0s">
                            <div className="">
                                {/*<h6 className="sub-title main-color">Basic Info About Shop</h6>*/}
                                <h2 className="section_title_two text-dark mb-0">We develop the strategy and fine-tune
                                    the tactic</h2> <br/>
                                <p className="paragraph">We believe that the price of listed financial instruments is
                                    determined by their fundamentals and their perception; therefore, we develop and
                                    execute robust IR programs, focused on achieving an optimal valuation.</p>
                                {/*List*/}

                                <CustomLink to="/servicios"
                                            className="get_btn btn_hover hover_color">Services</CustomLink>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 text-center mt-4 mt-md-0">
                            <div className="text-center text-lg-right wow fadeInRight" data-wow-delay="0.1s"
                                 data-wow-duration="1.0s">
                                <img src={img2}/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="about_consulting_area section_gap">
                <div className="container">
                    <div className="section_title black">
                        <h2 className="section_title_two text-dark">3 Pillars of the I.R. strategy</h2>
                    </div>
                    <div className="row about_info index">
                        <div className="col-lg-4 col-sm-6">
                            <a href="es/servicios/irstrat_2">
                                <div className="about_consult_item">
                                    <div className="about_img">
                                        <img alt={" "} src={solucion2}/>
                                    </div>
                                    <h3 className="sec_title">Strategic Message</h3>
                                    <p>
                                        “Message is king”, we outline and execute the strategy of your message to the
                                        markets, through the creation and delivery of a compelling investment thesis,
                                        that can bolster the valuation of your financial instruments.
                                    </p>
                                </div>
                            </a>
                        </div>
                        <div className="col-lg-4 col-sm-6">
                            <a href="es/servicios/irstrat_3">
                                <div className="about_consult_item">
                                    <div className="about_img">
                                        <img alt={" "} src={solucion3}/>
                                    </div>
                                    <h3 className="sec_title">Lobbying and Intelligence</h3>
                                    <p>
                                        “One cannot reach any port without a compass”, we help you to fully understand
                                        financial markets
                                        and rapidly expand your investor base and analyst coverage, using our
                                        specialized and well-established contact network comprised of the most important
                                        financial institutions, both domestic and international, as well as our broad
                                        portfolio of analysis tools.
                                    </p>
                                </div>
                            </a>
                        </div>
                        <div className="col-lg-4 col-sm-6">
                            <a href="es/servicios/irstrat_1">
                                <div className="about_consult_item">
                                    <div className="about_img">
                                        <img alt={" "} src={solucion1}/>
                                    </div>

                                    <h3 className="sec_title">Image and Identity</h3>
                                    <p>
                                        Since “a book is also judged by its cover”, we maximize the delivery quality of
                                        the message to the markets, that is, the quality of your IR materials (website,
                                        presentations and financial reports), and we provide robust technological tools
                                        for message distribution.
                                    </p>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
            <section className="offer_area section_gap">
                <div className="container">
                    <div className="section_title">
                        <h2>Image, identity and regulatory materials</h2>
                    </div>
                    <div className="service_slider service_slider_one owl-carousel">
                        <div className="offer_item">
                            <div className="offer_content">
                                <div className="inner_flip">
                                    <img alt={" "} className="repre-img" src={iredge}/>
                                    <h3>IR website</h3>
                                    <p>
                                        Total development: Spanish and English content, graphic identity, and a
                                        responsive and intelligent platform.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="offer_item">
                            <div className="offer_content">
                                <div className="inner_flip">
                                    <img alt={" "} className="repre-img" src={sustentabilidad}/>
                                    <h3>Annual report</h3>
                                    <p>
                                        Comprehensive development: information collection, graphic and content editing.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="offer_item">
                            <div className="offer_content">
                                <div className="inner_flip">
                                    <img alt={" "} className="repre-img" src={interactivo}/>
                                    <h3>Interactive report</h3>
                                    <p>
                                        Editing of Spanish and English content, graphic identity, responsive and
                                        intelligent platform.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="offer_item">
                            <div className="offer_content">
                                <div className="inner_flip">
                                    <img alt={" "} className="repre-img" src={sustentabilidad}/>
                                    <h3>Sustainability report</h3>
                                    <p>
                                        Report in accordance to the latest GRI standards, while achieving top-notch
                                        quality in the content (both in Spanish and English) and visual elements.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="offer_item">
                            <div className="offer_content">
                                <div className="inner_flip">
                                    <img alt={" "} className="repre-img" src={financieros}/>
                                    <h3>Financial feed</h3>
                                    <p>
                                        Development of Stock quote’ tickers, as well as interactive graphics and
                                        downloads.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="latest_blog_area">
                <div className="container">
                    <div className="section_title">
                        <h2>Latest Blog</h2>
                    </div>
                    <div className="row">

                        <div className="col-lg-4 col-md-6 wow fadeInRight" data-wow-delay="0.1s"
                             data-wow-duration="1.0s">
                            <div className="lt_blog_item">
                                <CustomLink to="/blog/fondos-cobertura" className="img_hover">
                                    <img alt={" "} src={noticias_21}/>
                                </CustomLink>
                                <div className="lt_content">
                                    <CustomLink to="/blog/fondos-cobertura">
                                        <h3>Fondos de cobertura</h3>
                                    </CustomLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 wow fadeInRight" data-wow-delay="0.1s"
                             data-wow-duration="1.0s">
                            <div className="lt_blog_item">
                                <CustomLink to="/blog/dos-practicas" className="img_hover">
                                    <img alt={" "} src={noticias_20}/>
                                </CustomLink>
                                <div className="lt_content">
                                    <CustomLink to="/blog/dos-practicas">
                                        <h3>Dos prácticas de relación con inversionistas que tomaron mayor relevancia
                                            debido al COVID-19</h3>
                                    </CustomLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 wow fadeInRight" data-wow-delay="0.1s"
                             data-wow-duration="1.0s">
                            <div className="lt_blog_item">
                                <CustomLink to="/blog/perspectivas-2021" className="img_hover">
                                    <img alt={" "} src={noticias_19}/>
                                </CustomLink>
                                <div className="lt_content">
                                    <CustomLink to="/blog/perspectivas-2021">
                                        <h3>Un vistazo a las perspectivas de 2021</h3>
                                    </CustomLink>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <section className="video_area">
                <div className="container">
                    <div className="row video_content">
                        <div className="col-md-6">
                            <h5 className="b_title">Let's work together to maximize the valuation of your financial
                                instruments</h5>
                            <h2>
                                We are leader in <br/>
                                market
                            </h2>
                            <a
                                className="video-pop pl-0"
                                href="https://player.vimeo.com/video/358367655?autoplay=1&title=0&byline=0&portrait=0"
                            >
                                Watch intro video
                                <br/> about us
                            </a>
                        </div>
                        <div className="col-md-6 d-flex align-items-center justify-content-center">
                            <a
                                className="video-pop mb-5 mt-5 mt-md-0"
                                href="https://player.vimeo.com/video/358367655?autoplay=1&title=0&byline=0&portrait=0"
                            >
                                <i className="flaticon-circular"/>
                            </a>
                        </div>
                    </div>
                </div>
            </section>

            <div id="modal-index" className="modal" tabIndex="-1">
                <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <div className="modal-body">
                            <a href="https://investorcloud.s3.amazonaws.com/irstrat/documents/wp/MPS-2020-EN.pdf"
                               target="_blank"><img src={popup} className="img-fluid" alt=""/></a>
                        </div>
                    </div>
                </div>
            </div>

        </Layout>
    )
}

export default IndexPage
